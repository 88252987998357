import React from 'react'
import { useState, useEffect, useContext, createElement } from 'react'
import Modal from 'react-modal';

import { createComponent } from './AssessmentComponents'
import { questions } from '../spec/utils'
import icons from '../icn/index'
import images from '../img/index'
import ModalContext from '../modal'
import { BcModal, BpNumbersRecentModal, BpModal, BmiModal, HormonalBcCorrectModal } from './ConfirmModals'
import Button from './Button'
import NavBar from '../components/NavBar'
import { useMediaQuery } from 'react-responsive'
import { ReactComponent as ZenaLogo } from '../img/zena-logo.svg'
import { ReturnButton } from './MoreInfo'
import useResponsive from '../responsive'
import { useRedirect } from '../prompt'

export function AssessmentScreenTitle({text, variant="secondary", style}) {
  return (
    <h1 className={variant} style={{lineHeight: "38px", marginTop: "4px", ...style}} dangerouslySetInnerHTML={{__html: text}}>
    </h1>
  );
}

export function AssessmentButton({onClick, variant='primary', children, ...rest}) {
  return (
    <Button onClick={onClick} variant={variant} {...rest}>
      {children}
    </Button>
  );
}

export function BaseScreen({
  heroImage, title, children, showButton=true, underButton, style={}, onSubmit, state={}, backButtonText='Back',
  buttonText='Next', disableContinue, components={}, subtitle, onBack, showBackButton, beforeTitle
}) {

  const isDesktop = useResponsive().desktop

  const outerStyle = {
    display: "flex",
    flexDirection: "column",
    width: "clamp(357px, 100%, 500px)",
    borderRadius: "5px",
    ...style.outer
  };

  const innerStyle = {
    padding: "0 32px",
    display: 'flex',
    flexDirection: 'column',
    ...style.inner
  }

  const buttonContainer = {
    padding: "60px 32px 32px 32px",
    display: "flex",
    justifyContent: "center",
    gap: isDesktop ? "32px" : "19px",
    ...style.buttonContainer || {}
  }

  const titleStyle = style.title || {}
  if (subtitle && !isDesktop) {
    titleStyle.marginBottom = "8px"
  }

  const buildTitle = () => (
    title && (
      typeof(title) == "string"
        ? <AssessmentScreenTitle text={title} style={titleStyle} />
        : createComponent(title)
    )
  )

  // TODO: could be a conditional override defined in the spec
  const buildBackButton = () => (
    state.state == "returningedit"
    ? <ReturnButton/>
    : <AssessmentButton onClick={onBack} variant="primary outline thin" style={{flex: "0 1 400px"}}>{backButtonText}</AssessmentButton>
  )

  return (
    <div style={outerStyle}>
      { beforeTitle }
      { buildTitle() }
      { subtitle && <span style={{padding: "0 32px 16px", ...style.subtitle}}>{subtitle}</span>}
      <div style={innerStyle}>
        {children}
      </div>
      <div style={buttonContainer}>
        {showBackButton && buildBackButton()}
        {showButton && <AssessmentButton onClick={onSubmit} variant="primary" disabled={disableContinue} style={{flex: "0 1 400px"}}>{buttonText}</AssessmentButton>}
      </div>
    </div>
  )
}

export function KickoutScreen({id, title, text, heroImage, showStopSignImage=true, onClick, onEvent, children}) {
  useEffect(() => {
    onEvent({componentId: id, type: 'SCREEN_DISPLAYED'})
  }, [id])

  useRedirect("/" + window.location.search)

  const stop = showStopSignImage ? (
    <div style={{display: "flex", justifyContent: "center", marginBottom: "32px"}}>
      <img src={images.stop} alt="" style={{width: "102px", height: "auto"}}/>
    </div>
  ) : null

  return (
    <BaseScreen
      heroImage={heroImage}
      title={title}
      onClick={onClick}
      showButton={false}
      showBackButton={false}
      beforeTitle={stop}
    >
      {children}
    </BaseScreen>
  )
}

// TODO - the hormonalBirthControlScreen will probably be need removed from the spec

const confirmModals = {
  bpnumbers: ({answers: {initialSystolic, initialDiastolic}, ...rest}) => <BpModal systolic={initialSystolic} diastolic={initialDiastolic} {...rest} />,
  hormonalBirthControlScreen: BcModal,
  birthcontrolAScreen: BcModal,
  birthcontrolBScreen: BcModal,
  knowBpNumbers: (props) => <BpNumbersRecentModal {...props} id="initialBpNumbersRecent" />,
  BMIObesityScreen: BmiModal,
  hormonalBcCorrectModal: HormonalBcCorrectModal
}

export function Screen({ id, onEvent, onSubmit, showContinueButton, style, title, subtitle, children, heroImage, underButton, buttonText, confirm, answers, getValue, screenSpec, onBack, showBackButton, state }) {
  useEffect(() => {
    onEvent({componentId: id, type: 'SCREEN_DISPLAYED'})
  }, [id])

  const setModal = useContext(ModalContext)

  const confirmBeforeSubmit = a => {
    const errs = validate(screenSpec, answers)
    if (Object.keys(errs).length > 0) {
      return onSubmit(a) // prevents modal from showing if validation errors
    }

    for (let i = 0; i < confirm.length; i++) {
      const c = confirm[i]
      const fn = new Function(`return state => ${c.cond}`)()
      if (fn(answers)) {
        const key = c.modal || id

        const handleConfirm = (v) => {
          setModal()
          onEvent({type: "CONFIRM", componentId: `${key}Confirm`})
          onSubmit({...a, ...v})
        }

        const closeModal = () => setModal()

        const outerStyle = {
          display: "flex",
          flexDirection: "column",
          borderRadius: "5px",
        };

        const Component = confirmModals[key]
        const createModal = () => (
          <div style={outerStyle}>
            <Component answers={answers} onConfirm={handleConfirm} onClose={closeModal} onEvent={onEvent} getValue={getValue}/>
          </div>
        )

        setModal(createModal)
        return
      }
    }

    onSubmit(a)
  }

  const handleSubmit = confirm ? confirmBeforeSubmit : onSubmit
  const remaining = questions(screenSpec).filter(q => answers[q.id] === undefined)

  return (
    <BaseScreen
      title={title}
      subtitle={subtitle}
      heroImage={heroImage}
      style={style || {}}
      showButton={showContinueButton == true}
      disableContinue={remaining.length}
      underButton={underButton || {}}
      buttonText={ buttonText || "Next" }
      onBack={onBack}
      showBackButton={showBackButton}
      state={state}

      onSubmit={() => handleSubmit()}
    >
      { children }
    </BaseScreen>
  )
  //return (
  //  <BaseScreen title={screenSpec.title}
  //   showButton={screenSpec.showContinueButton == true} onClick={() => onSubmit()} buttonText="Next">
  //    { React.createElement(React.Fragment, {}, ...children) }
  //  </BaseScreen>
  //)
}

// internal assessment state to protobuf assessment state
export function encodeScreenState(state) {
  return {...state, answers: JSON.stringify(state.answers)}
}

// protobuf assessment state to internal assessment state
export function decodeAssessmentState(pbstate) {
  return {
    index: pbstate.index,
    state: pbstate.state,
    kickoutReason: pbstate.kickoutreason,
    isCompleted: pbstate.iscompleted,
    completed: pbstate.completed,
    answers: JSON.parse(pbstate.answers),
    isTaking: pbstate.state == "assessment",
    isReviewing: pbstate.state == "editablesummary",
    isEditing: pbstate.state == "edit" || pbstate.state == "returningedit",
    isKickout: pbstate.state == "kickout",
    isOutcome: pbstate.outcome != "",
    outcome: pbstate.outcome,
    editIndex: pbstate.editindex,
  }
}

// validate questions starting from root and return any errors
function validate(root, answers) {
  const errs = {}
  for (const q of questions(root)) {
    const answer = answers[q.id]
    if (answer === undefined) {
      errs[q.id] = "This question is required"
      continue
    }

    if (q.validation) {
      for (const validation of q.validation) {
        const fn = new Function(`return answer => ${validation.cond}`)()
        if (fn(answer)) {
          errs[q.id] = validation.err
          break
        }
      }
    }
  }
  return errs
}

export function Assessment({spec, state, showBackButton, onSubmit, onEvent, onBack}) {
  const [auditTrail, setAuditTrail] = useState([]) // trail of events

  const initScreenState = () => {
    const screenState = {answers: {}}
    questions(spec.children[state.index]).forEach(q => {
      if (state.answers[q.id] !== undefined) {
        screenState.answers[q.id] = state.answers[q.id]
      }
    })
    return screenState
  }

  const [screenState, setScreenState] = useState(initScreenState())
  const [shouldShowErrs, setShouldShowErrs] = useState(false)

  const screenErrs = validate(spec.children[state.index], screenState.answers)

  useEffect(() => {
    setScreenState(initScreenState())
  }, [state])

  const handleEvent = e => {
    if (e.type == 'UPDATED_ANSWER') {
      setScreenState(s => ({answers: {...s.answers, [e.componentId]: e.newValue}}))
    }
    if (e.type == 'UPDATED_ANSWERS') {
      setScreenState(s => ({answers: {...s.answers, ...e.answers}}))
    }
    setAuditTrail(t => [...t, e])
    onEvent(e)
  }

  const handleSubmit = a => {
    // yes/no buttons submit their answers at the same time the screen is submitted
    const all = {answers: {...screenState.answers, ...a}}

    const errs = Object.keys(screenErrs).length > 0
    if (errs) {
      if (!shouldShowErrs) {
        setShouldShowErrs(true)
      }
      return
    }

    if (shouldShowErrs) {
      setShouldShowErrs(false)
    }

    onSubmit(all)
  }

  const hideErrs = () => {
    setShouldShowErrs(false)
  }

  const screenSpec = spec.children[state.index]
  const getValue = qid => screenState.answers[qid]
  const props = {
    isLoading: false,
    onSubmit: handleSubmit,
    onEvent: handleEvent,
    getValue,
    answers: {...state.answers, ...screenState.answers},
    shouldShowErrs,
    screenErrs,
    hideErrs,
    screenSpec,
    onBack,
    showBackButton,
    state,
  }

  return createComponent(screenSpec, props)
}

