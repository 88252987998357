import React, { Component, useState, useContext, useEffect, useRef } from 'react'

import Modal from 'react-modal'

import NavBar from './NavBar'
import { BaseScreen } from './Assessment'
import { moreinfo } from '../icn'
import { ReactComponent as InfoIcon } from '../icn/more-info.svg'
import LayoutScreen from './Layout'
import router from '../history'
import { useAudit } from '../audit'
import { client } from '../client'
import { AssessmentButton } from './AssessmentComponents'
import { skipPrompt } from '../prompt'
import { useStateWithSessionStorage } from '../hooks'

const modalStyle = {
  content: {
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: 'fixed',
    padding: 0,
    border: 0,
    background: "#FCFCFC"
  }
}

const innerStyle = {
  flex: "1 1 0",
  height: "100%",
}

// export default class MoreInfo extends Component {
//   constructor(props) {
//     super(props)
// 
//     this.state = {
//       showingInfo: false,
//     }
//   }
// 
//   render() {
//     const { text, children, style } = this.props
//     const { showingInfo } = this.state
// 
//     const closeInfo = () => this.setState({showingInfo: false})
//     const showInfo = (e) => {
//       e.preventDefault()
//       this.setState({showingInfo: true})
//     }
// 
//     return (
//       <div style={style}>
//         <button className="btn primary link" onClick={showInfo}>{ text }</button>
//         <Modal style={modalStyle} isOpen={showingInfo} onRequestClose={closeInfo}>
// 
//           <div style={{display: "flex", flex: "1", flexDirection: "column", minHeight: "100%"}}>
//             <NavBar variant="secondary" onBack={closeInfo} />
//             <div style={innerStyle}>
//               <BaseScreen
//                 title={"More Info"}
//                 showButton={true}
//                 buttonText={"Back"}
//                 onSubmit={closeInfo}
//               >
//                 <div className='column' style={{ display: 'flex', flexDirection: 'column', ...style}}>
//                   { children }
//                 </div>
//               </BaseScreen>
//             </div>
//           </div>
// 
//         </Modal>
//       </div>
//     )
//   }
// }

function Audited({children}) {
  useAudit({type: "MOREINFO_DISPLAYED"})

  return (
    <>
      { children }
    </>
  )
}

function BaseMoreInfo(props) {
  const { component, children, style, title="More Info" } = props
  const [showingInfo, setShowingInfo] = useStateWithSessionStorage("BaseMoreInfo_showingInfo", false)
  //useAudit({type: "MOREINFO_DISPLAYED"})

  const closeInfo = () => setShowingInfo(false)
  const showMoreInfo = (e) => {
    e.preventDefault()
    setShowingInfo(true)
  }

  const LinkComponent = component
  return (
    <>
      <LinkComponent showMoreInfo={showMoreInfo}/>

      <Modal style={modalStyle} isOpen={showingInfo} onRequestClose={closeInfo}>
        <div style={{display: "flex", flex: "1", flexDirection: "column", minHeight: "100%"}}>
          <LayoutScreen>
            <div style={{...innerStyle, display: "flex", flexDirection: "column"}}>
              <BaseScreen
                title={title}
                showButton={true}
                buttonText={"Back"}
                onSubmit={closeInfo}
              >
              <Audited>
                { children }
              </Audited>
              </BaseScreen>
            </div>
          </LayoutScreen>
        </div>
      </Modal>
    </>
  )
}

function MoreInfoModal({isOpen, onRequestClose, children}) {
  return (
    <Modal style={modalStyle} isOpen={isOpen} onRequestClose={onRequestClose}>
      <div style={{display: "flex", flex: "1", flexDirection: "column", minHeight: "100%"}}>
        <LayoutScreen>
          <div style={{...innerStyle, display: "flex", flexDirection: "column"}}>
            <BaseScreen
              title="More Info"
              showButton={true}
              buttonText={"Back"}
              onSubmit={onRequestClose}
            >
            <Audited>
              { children }
            </Audited>
            </BaseScreen>
          </div>
        </LayoutScreen>
      </div>
    </Modal>
  )
}

export function BaseModal({children, style, ...rest}) {
  return (
    <Modal style={{...modalStyle, ...style}} {...rest}>
      {children}
    </Modal>
  )
}

export function FullScreenModal({isOpen, onRequestClose, title, showButton, buttonText, onSubmit, children, showBackButton, onBack, screenStyle}) {
  return (
    <Modal style={modalStyle} isOpen={isOpen} onRequestClose={onRequestClose}>
      <div style={{display: "flex", flex: "1", flexDirection: "column", minHeight: "100%"}}>
        <LayoutScreen>
          <div style={{...innerStyle, display: "flex", flexDirection: "column"}}>
            <BaseScreen
              title={title}
              showButton={showButton}
              buttonText={buttonText}
              onSubmit={onSubmit}
              onBack={onBack}
              showBackButton={showBackButton}
              style={screenStyle}
            >
              { children }
            </BaseScreen>
          </div>
        </LayoutScreen>
      </div>
    </Modal>
  )
}

export function MoreInfoIcon ({ children }) {
  return (
    <BaseMoreInfo
      component={({ showMoreInfo }) => {

        return (
          <a href="#" onClick={showMoreInfo} style={{ marginLeft: "4px"}}>
            <span className="sr-only">More info</span>
            <InfoIcon/>
          </a>
        )
      }}
    >
     { children }
    </BaseMoreInfo>
  )
}


function MoreInfoIconPlus({style, ...props}) {
  const defaultStyle = {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    //padding: "6px",
    margin: "6px",
    fontSize: "18px",
    lineHeight: "18px",
    height: 24,
    borderRadius: "4px",
    verticalAlign: "bottom",
  }

  const defaultOnClick = e => e.preventDefault()
  return (
    <a href="#" style={{...defaultStyle, ...style}} onClick={defaultOnClick} {...props}>
      <span><InfoIcon/></span>
      <span style={{marginLeft: 6}}> More Info </span>
    </a>
  )
}

export function MoreInfoIconPlusComponent({ children, style }) {
  style = {
    margin: "0 0 18px 0",
    justifyContent: "flex-start",
    alignSelf: "start",
    ...style
  }
  return (
    <BaseMoreInfo
      component={({ showMoreInfo }) => {
        return (
          <MoreInfoIconPlus onClick={showMoreInfo} style={style} />
        )
      }}
    >
     { children }
    </BaseMoreInfo>
  )
}

//<a href="#" onClick={showMoreInfo} style={{marginLeft: 4}} onMouseEnter={(e) => {
//  if (!hover) {
//    console.log("target", e.target.getBoundingClientRect())
//    setTooltip(e.target.getBoundingClientRect())
//    setHover(true)
//  }
//}}>
//  <InfoIcon/>
//</a>

function Tooltip({Component}) {
  const [hover, setHover] = useState(true)
  const [tooltip, setTooltip] = useState({})
  const overlayNode = useRef(null)
  const ref = useRef(null)

  useEffect(() => {
    setTooltip(ref.current.getBoundingClientRect())
    setHover(true)
  }, [])

  const setHoverFalse = () => setHover(false)

      // onMouseEnter={(e) => {
      //   if (!hover) {
      //     //setTooltip(e.target.getBoundingClientRect())
      //     //setHover(true)
      //   }
      // }}
  return (
    <>
      <div
        ref={ref}
        style={{
          display: "inline-block",
        }}
      >
        <Component/>
      </div>
        <Modal
          isOpen={hover}
          onRequestClose={setHoverFalse}
          overlayRef={node => {
            overlayNode.current = node
          }}
          onAfterOpen={() => {
            overlayNode.current.style.backgroundColor = "rgba(0,0,0,0.75)"
          }}
          style={{
            content:{
              left: tooltip.left-20,
              top: tooltip.top+(tooltip.height+1|0),
              maxWidth: 450,
              //width: 400,
              //height: 400,
              background: "none",
              outline: "none",
              border: "none",
              padding: 0,
              margin: 0,
              overflow: "visible",
            },
            overlay: {
              backgroundColor: "rgba(0,0,0,0.1)",
              transition: "background-color .4s ease-out",
            }
          }}
          >
          <div
            onClick={setHoverFalse}
          style={{
            position: "fixed",
            left: tooltip.left,
            top: tooltip.top,
            borderRadius: "4px",
            background: "white",
          }}>
            <Component/>
          </div>
          <div style={{
            marginLeft: (tooltip.width*.66)|0,
            marginTop: 2,
            width: 0,
            height: 0,
            borderLeft: "16px solid transparent",
            borderRight: "16px solid transparent",
            borderBottom: "16px solid white",
          }}>
          </div>
          <div style={{
            background: "white",
            //border: "1px solid rgb(204, 204, 204)",
            borderRadius: "4px",
            padding: "30px",
          }}>
            <span>On some screens you may see an information button like this: </span>
              <MoreInfoIconPlus style={{padding: 0, margin: 0}}/>
            <span>.
            When you see this button, you can click it to find out additional information about the topic on that page.</span>
            <div style={{marginTop: "20px"}}>
            <AssessmentButton onClick={setHoverFalse} >Close</AssessmentButton>
            </div>
          </div>
        </Modal>
    </>
  )
}

export function TitleInfo({ left, right, children }) {
  const [showingInfo, setShowingInfo] = useStateWithSessionStorage("TitleInfo_showingInfo", false)

  let title = left
  if (right) {
    title = `${title} ${right}`
  }


  return (
    <div style={{}}>
      <h1 className="secondary" style={{lineHeight: "38px", marginTop: "4px", marginBottom: "4px"}}>
        <span>{title}</span>
      </h1>
      <div style={{margin: "0 0 16px 26px"}}>
        <MoreInfoIconPlus onClick={e => {
          e.preventDefault()
          setShowingInfo(true)
        }} />
        <MoreInfoModal isOpen={showingInfo} onRequestClose={() => setShowingInfo(false)}>
          { children }
        </MoreInfoModal>
      </div>
    </div>
  );
}

export function ReturnButton() {
  const [showing, setShowing] = useState(false)

  const screenStyle = {
    buttonContainer: {
      paddingTop: 32,
    }
  }

  return (
    <>
      <AssessmentButton onClick={() => setShowing(true)} variant="primary outline thin" style={{whiteSpace: "nowrap"}}>Return Later</AssessmentButton>
      <FullScreenModal
        isOpen={showing}
        title="Return to this site after you get your blood pressure measured"
        showButton={true}
        showBackButton={true}
        buttonText="Finish"
        onSubmit={() => {
          skipPrompt()
          router.push("/")
        }}
        onBack={() => setShowing(false)}
        screenStyle={screenStyle}
      >
        <p>Since you do not have blood pressure numbers taken within the last 3 months, you can return later to enter them and complete your health survey.</p>
        <BloodPressureInfo style={{marginTop: 60}}/>
      </FullScreenModal>
    </>
  )
}

export function TitleInfoTooltip({ left, right, children }) {
  const [showingInfo, setShowingInfo] = useStateWithSessionStorage("TitleInfoTooltip_showingInfo", false)

  let title = left
  if (right) {
    title = `${title} ${right}`
  }


  return (
    <div style={{}}>
      <h1 className="secondary" style={{lineHeight: "38px", marginTop: "4px", marginBottom: "4px"}}>
        <span>{title}</span>
      </h1>
      <div style={{margin: "0 0 16px 26px"}}>
        <Tooltip Component={() => (
          <>
          <MoreInfoIconPlus onClick={e => {
            e.preventDefault()
            setShowingInfo(true)
          }} />
          <MoreInfoModal isOpen={showingInfo} onRequestClose={() => setShowingInfo(false)}>
            { children }
          </MoreInfoModal>
          </>
      )}/>
      </div>
    </div>
  );
}

export function BloodPressureInfo({style}) {
  return (
    <BaseMoreInfo
      title="You need to enter a current (taken in past 3 months) blood pressure"
      component={({ showMoreInfo }) => (
        <BloodPressureInfoButton style={style} onClick={showMoreInfo} />
      )}
    > 
      <BloodPressureInfoContent />
    </BaseMoreInfo>
  )
}

export function BloodPressureInfoButton({style, onClick}) {
  style = {
    width: "min(100%, 374px)",
    padding: "14px 21px",
    borderRadius: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
    textAlign: "left",
    height: "auto",
    gap: "16px",
    ...style
  }
  return (
    <button className="btn tertiary" style={style} onClick={onClick}>
      <b>How to check your blood pressure</b>
      <svg width="15" height="23" viewBox="0 0 15 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.42969 2L12.9353 10.8214L2.42969 21" stroke="white" strokeWidth="3" strokeMiterlimit="16" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </button>
  )
}

export function BloodPressureInfoContent() {
  return (
    <div>
      <p style={{marginBottom: "12px"}}>There are fast and easy ways to get your blood pressure measured:</p>
      <ul>
        <li>check with your doctor</li>
        <li>visit the local fire station</li>
        <li>check with your school nurse</li>
        <li>get your blood pressure from your health portal if you have one</li>
        <li>use a home blood pressure device if you have one</li>
        <li>use a blood pressure machine located in most pharmacy stores</li>
      </ul>
    </div>
  )
}