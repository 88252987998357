import { useState, useEffect, useRef, useLayoutEffect, useContext, createElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useSearchParams, Outlet, useOutletContext } from 'react-router-dom'
import { Modal } from '../modal';

import LayoutScreen from '../components/Layout'
import router, { routerpush } from '../history'
import welcomeMobile from '../img/welcome.png'
import welcomeDesktop from '../img/welcome-desktop.png'
import lock from '../icn/lock.svg'
import { Layout, BaseScreen } from '../components/Assessment'
import { AssessmentButton, createComponent, RadioList } from '../components/AssessmentComponents'
import icons from '../icn/index'
import PrivacyRest from '../components/PrivacyRest'

import { Auth, googleAuth, appleAuth } from '../components/Auth'
import { FullScreenModal, BloodPressureInfo, BloodPressureInfoButton, BloodPressureInfoContent } from '../components/MoreInfo'
import { assessmentStarted, guestLogin, reserveAssessmentNumber, setAssessmentNumber, wait } from '../reducers'
import ModalContext from '../modal';
import { authClient, client } from '../client';
import { ReactComponent as Exclamation } from '../icn/circle-exclamation.svg'
import useResponsive from '../responsive';
import { useMetrics } from '../metrics'
import { setPrescreenState } from '../components/PrescreenGuard'

import '../css/LandingPage.css'
import '../css/PrivacyNotice.css'

function formatAssessmentNumber(n) {
  if (isNaN(n)) {
    return "N/A"
  }

  if (n < 0) { return "" }
  if (n > 99) { return n }

  return ("00" + n).slice(-3)
}

function getAttempts() {
  // returns an array of attempts like:
  // [
  //   {timestamp: 1672960933830}, // timestamp is in milliseconds
  //   {timestamp: 1672960946649},
  //   ...
  // ]
  const v = localStorage.getItem("attempts")
  if (!v) {
    return []
  }
  return JSON.parse(v)
}

function addAttempt() {
  const attempts = [...getAttempts(), {timestamp: Date.now()}]
  localStorage.setItem("attempts", JSON.stringify(attempts))
}

function hasRemainingAttempts() {
  
  const max = 3
  const attempts = getAttempts()

  const isRecent = timestamp => {
    const millisecond = 1
    const second = 1000 * millisecond
    const minute = 60 * second
    const hour = 60 * minute
    
    const recentWindow = 1 * hour

    const now = Date.now()
    const elapsed = now - timestamp
    if (elapsed <= recentWindow) {
      return true
    }
    return false
  }

  const recentAttempts = attempts.filter(a => isRecent(a.timestamp))
  if (recentAttempts.length >= max) {
    return false
  }
  return true
}

function AttemptModalContent({onClose}) {
  return (
    <>
      <h1 className="secondary" style={{paddingLeft: 0, fontSize: "24px", lineHeight: "32px"}}>Limit Reached</h1>
        <p style={{flex: "auto"}}>You may only attempt this health survey 3 times per hour.</p>
      <button className="btn primary" onClick={() => onClose()} style={{marginBottom: "32px"}}>Close</button>
    </>
  )
}

export default function LandingPage() {
  const dispatch = useDispatch()
  const assessment = useSelector(state => state.assessment)
  const auth = useSelector(state => state.auth)
  const setModal = useContext(ModalContext)
  const [searchParams] = useSearchParams()
  const clinician = searchParams.get("clinician")
  const recordMetric = useMetrics()

  useEffect(() => {
    if (clinician) {
      client.getClinicianAssessment({token: clinician})
        .then(resp => {
          const { assessmentId, assessmentNumber } = resp
          dispatch(setAssessmentNumber(assessmentId, assessmentNumber))
        })
    } else {
      dispatch(reserveAssessmentNumber())
    }
  }, [])

  useLayoutEffect(() => {
    const header = document.querySelector('h1')
    if(header) header.focus()
  })

  const signInWithGoogle = async (mode) => {
    await authClient.guestLogin()
    let res = await googleAuth(mode)
    if (res.status === "no-account") {
      setAuthModalState(s => ({...s, show: true}))
      setSetting("missing_account")
      setShowError(true)
      router.push("/numbers" + window.location.search)
    } else {
      const returnable = res?.lastAssessment && res.state !== "kickout"
      if (returnable) {
        await recordMetric({priorUse: hasUsed}, res.lastAssessment)
        routerpush(`/return/${res.lastAssessment}`)
      } else {
        addAttempt()
        dispatch(assessmentStarted(hasUsed))
      }
    }
  }

  const signInWithApple = async (mode) => {
    await authClient.guestLogin()
    let res = await appleAuth(mode)
    if (res.status === "no-account") {
      setAuthModalState(s => ({...s, show: true}))
      setSetting("missing_account")
      setShowError(true)
      router.push("/numbers" + window.location.search)
    } else {
      const returnable = res?.lastAssessment && res.state !== "kickout"
      if (returnable) {
        await recordMetric({priorUse: hasUsed}, res.lastAssessment)
        routerpush(`/return/${res.lastAssessment}`)
      } else {
        addAttempt()
        dispatch(assessmentStarted(hasUsed))
      }
    }
  }

  const signInAsGuest = async () => {
    await authClient.guestLogin()
    addAttempt()
    dispatch(assessmentStarted(hasUsed))
  }

  const recordEvent = async e => {
    await client.recordEvent({assessmentid: assessment.assessmentId, eventtype: e.type, data: JSON.stringify(e)})
  }

  const onSubmit = async () => {
    if (clinician) {
      const { assessmentId, authToken } = await client.takeClinicianAssessment({
        token: clinician,
        metrics: JSON.stringify({priorUse: hasUsed}),
      })
      await authClient.setToken({token: authToken})
      router.push(`/assessment/${assessmentId}`)
      return
    }

    let mode = 'signup'
    if (setting === "initial" && hasUsed === "yes") {
      mode = "login"
    }

    const selectedAuthMethod = searchParams.get("auth")

    if (selectedAuthMethod === "google") {
      signInWithGoogle(mode)
    } else if (selectedAuthMethod === "apple") {
      signInWithApple(mode)
    } else if (selectedAuthMethod === "guest") {
      signInAsGuest()
    }
  }

  const style = {
    authModal: {
      button: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "56px",
        width: "clamp(200px, 100%, 500px)",
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "24px",
        borderRadius: "28px",
        border: "none",
        backgroundColor: "transparent",
        color: "rgb(14, 83, 64)",
        cursor: "pointer",
        transition: "box-shadow 150ms ease",
      }
    },
    main: {}
  }

    style.main = {
      display: "flex", 
      flexDirection: "column",
      flex: 1
    }

  const [setting, setSetting] = useState("initial") // one of initial, missing_account  
  const [authModalState, setAuthModalState] = useState({show: false, step: "auth"})
  const [showError, setShowError] = useState(false)
  const [hasUsed, setHasUsed] = useState('')

  useEffect(() => {
    // reset modal when closed
    if (!authModalState.show) {
      setAuthModalState(s => ({...s, step: "auth"}))
    }
  }, [authModalState.show])

  return (
    <main style={style.main}>
      <AuthModal
        state={authModalState}
        setStep={s => setAuthModalState(state => ({...state, step: s}))}
        onApple={() => {
          if (setting === "initial") {
            setAuthModalState(s => ({...s, show: false}))
            setPrescreenState(prescreen => ({...prescreen, numbers: true}))
            routerpush("/product", {auth: "apple"})
          } else if (setting === "missing_account") {
            signInWithApple("signup")
          }
        }}
        onGoogle={() => {
          if (setting === "initial") {
            setAuthModalState(s => ({...s, show: false}))
            setPrescreenState(prescreen => ({...prescreen, numbers: true}))
            routerpush("/product", {auth: "google"})
          } else if (setting === "missing_account") {
            signInWithGoogle("signup")
          }
        }}
        onGuest={() => {
          if (setting === "initial") {
            setAuthModalState(s => ({...s, step: "confirm"}))
          } else if (setting === "missing_account") {
            setAuthModalState(s => ({...s, show: false}))
            setHasUsed('')
            setPrescreenState(prescreen => ({...prescreen, numbers: true}))
            routerpush("/product", {auth: "guest"})
          }
        }}
        onConfirmGuest={() => {
          setAuthModalState(s => ({...s, show: false}))
          setPrescreenState(prescreen => ({...prescreen, numbers: true}))
          routerpush("/product", {auth: "guest"})
        }}
        onRequestClose={() => setAuthModalState(s => ({...s, show: false}))}
        showError={showError}
      />
      <Outlet context={{authModalState, setAuthModalState, hasUsed, setHasUsed, onSubmit, recordEvent}}/>
    </main>
  )
}

      //{createElement(screens[index], null)}
// TODO
// [ ] fix any other uses of the auth modal since i changed it
// [ ] bug with modal overflow on smaller screens
// [ ] bug, if you try going through twice as a guest, it doesn't work
  //  e.g. 
  //  first pass: guest -> complete -> sign in with google, works
  //  second pass: guest -> complete -> sign in with google, fails (403 error).
function AuthModal({state, setStep, onRequestClose, onConfirmGuest, showError, onApple, onGoogle, onGuest}) {
  const { desktop } = useResponsive()

  const style = {
    error: {
      box: {
        background: "rgb(225, 0, 0)",
        borderRadius: "8px",
        padding: "18px",
        color: "white",
        marginBottom: "16px"
      },
      text: {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "500",
        color: "white",
        margin: 0,
      }
    },
    cancelButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "56px",
      width: "clamp(200px, 100%, 500px)",
      fontSize: "16px",
      fontWeight: "bold",
      lineHeight: "24px",
      borderRadius: "28px",
      border: "none",
      backgroundColor: "transparent",
      color: "var(--primary)",
      cursor: "pointer",
      transition: "box-shadow 150ms ease",
      margin: "16px 0 32px"
    },
    termsText: {
     fontSize: "16px",
     lineHeight: "20px",
     color: "rgba(99, 99, 99, 1)",
     textDecorationSkipInk: "none",
    },
    modal: {
      overlay: {
        padding: "18px"
      },
      content: {
        padding: 0
      }
    }
  }

  const { step } = state
  const [providerName, setProviderName] = useState('') // this is only stored for the error message

  const TermsLink = ({children}) => {
    const style = {
      textDecoration: "underline",
      color: "inherit",
      fontWeight: "inherit",
    }
    return (
      <a href="#" onClick={e => e.preventDefault()} style={style}>{children}</a>
    )
  }

  return (
    <Modal
        className="modal"
        overlayClassName="overlay"
        isOpen={state.show}
        onRequestClose={onRequestClose}
        showCloseButton={step === "confirm"}
        style={style.modal}
      >
        {
          step === 'auth' && (
            <div style={{margin: "32px 24px"}}>
              {showError && (
                <div style={style.error.box}>
                  <div style={{display: "flex", gap: "9px", alignItems: "center"}}>
                    <div>
                      <Exclamation />
                    </div>
                      <p style={style.error.text}>We do not have any accounts connected with your <span style={{textTransform: "capitalize"}}>{providerName}</span> Account. If you do not have an account please sign up using your Google or Apple ID below.</p>
                  </div>
                </div>
              )}
              <div style={{display: "flex", justifyContent: "center"}}>
              <div style={{maxWidth: "289px"}}>
                <h1 className="secondary" style={{padding: 0, textAlign: "center", marginBottom: "40px"}}>Sign up or log into your account</h1>
                <Auth onSelect={(name, _) => {
                  setProviderName(name)

                  if (name === "guest") {
                    onGuest()
                  } else if (name === "google") {
                    onGoogle()
                  } else if (name === "apple") {
                    onApple()
                  }
                }} />
                <button style={style.cancelButton} onClick={() => onRequestClose()}>
                  <span>Cancel</span>
                </button>
              </div>
              </div>
              <p style={{...style.termsText, margin: 0}}>
                By using your Google or Apple ID you accept our <TermsLink>terms of use</TermsLink> and <TermsLink>privacy policy</TermsLink>.<br />
                <br />
                <br />
                If you continue as a guest, your answers will not be saved when you quit and you will need to restart.
              </p>
            </div>
          )
        }
        {
          step === 'confirm' && ( 
            <div style={{margin: desktop ? "48px 64px 57px 64px" : "66px 28px 38px 28px"}}>
              <h1 className="secondary" style={{padding: 0, margin: "0 0 40px 0"}}>Continue as a guest</h1>
              <p style={{marginBottom: "20%"}}>
                You will have to re-enter your information each time you want to purchase Zena if you continue as a guest.<br />
                <br />
                <b>You will also have a chance to create an account later.</b>
              </p>
              <div style={{display: "flex", flexDirection: "column", gap: "24px"}}>
                <button className='btn primary outline' onClick={() => setStep('auth')}>Go Back</button>
                <button className='btn primary' onClick={onConfirmGuest}>Confirm</button>
              </div>
            </div>
          )
        }
        
      </Modal>
  )
}

export function WelcomeScreen({deactivated=false}) {
  const assessment = useSelector(state => state.assessment)
  const [searchParams] = useSearchParams()
  const clinician = searchParams.get("clinician")
  const invite = searchParams.get("invite")

  if (!deactivated) {
  return (
    <div id="welcome">
      <div className="content">
        <div className="image-container">
          <img className="mobile" src={welcomeMobile} alt="" />
          <img className="desktop" src={welcomeDesktop} alt="" />
        </div>
        <div className='cta'>
          <h1 className="secondary" tabIndex="1">Welcome to Zena</h1>
          <p>Find out if Zena is safe for you!</p>
          <p>
            Take this health survey to find out!<br />
            This will only take a few minutes.<br />
            Let's get started!<br />
          </p>
          <div className='btn-group'>
            <AssessmentButton onClick={async () => {
              if (!invite && !clinician) { return }

              if (invite) {
                try {
                  await authClient.getToken()
                } catch (err) {
                  const errJson = await err.json()
                  if (errJson.status === "deactivated") {
                    router.push("/deactivated" + window.location.search)
                    return
                  }
                  throw err
                }
              }

              setPrescreenState(() => ({
                assessmentId: assessment.assessmentId,
                welcome: true
              }))

              router.push("/privacy" + window.location.search)

            }}>Get Started</AssessmentButton>
            <div className="private">
              <img src={lock} alt=""/>
              <p>Private and secure</p>
            </div>
            <div style={{padding: "92px 0 0 0"}}>
              <span style={{fontWeight: "bold", fontSize: "20px"}}>
                Assessment ID - {`${formatAssessmentNumber(assessment.assessmentNumber)}`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  }

  return (
    <div id="welcome">
      <div className="content">
        <div className="image-container">
          <img className="mobile" src={welcomeMobile} alt="" />
          <img className="desktop" src={welcomeDesktop} alt="" />
        </div>
        <div className='cta'>
          <h1 className="secondary" tabIndex="1">Sorry this account has been deactivated.</h1>
          <p>If you believe this is an error, please contact study staff for support.</p>
          <p>Thank you for visiting.</p>
        </div>
      </div>
    </div>
  )
}

export function PrivacyNotice({onBack, onSubmit, recordEvent}) {
  const [expanded, setExpanded] = useState(false)
  const className = `privacy-notice ${expanded ? "expanded" : "hidden"}`

  const handleExpand = () => {
    if (!expanded) {
      recordEvent({type: "PRIVACY_EXPANDED"})
    }
    setExpanded(expanded => !expanded)
  }

  return (
    <div className={className}>
      <div>
        <p style={{margin: "24px 0"}}>
          This privacy notice for <b>Zena</b> ("Company," "we," "us," or "our"), describes how and why we
          might collect, store, use, and/or share ("process") your information when you use our
          services ("Services"), such as when you:
          <br/><br/>
          * Visit our website at <b>https://www.ZENA-TBD.com</b>, or any website of ours that links to this privacy notice
        </p>
      </div>
      <div className="buttons">
        <AssessmentButton onClick={() => onBack()} variant="primary outline thin">Back</AssessmentButton>
        <AssessmentButton onClick={async () => {
          await recordEvent({type: "PRIVACY_ACKNOWLEDGED"})
          onSubmit()
        }} variant="primary">Next</AssessmentButton>
      </div>
      <div>
        <button className="unstyled privacy-expand" onClick={handleExpand} aria-expanded={expanded} aria-controls="privacy-notice-full">
          <img id="privacy-arrow" src={icons["arrow-down"]} alt="" style={{marginRight: "8px"}} aria-hidden="true" />
          <span className="privacy-optional">(Optional) Click here to review the full privacy notice.</span>
        </button>
        <div id="privacy-notice-full">
          <div style={{marginTop: "24px"}}>
          { expanded && <PrivacyRest /> }
          </div>
        </div>
      </div>
    </div>
  )
}

export function PrivacyScreen() {
  const { recordEvent } = useOutletContext()

  return (
    <LayoutScreen>
      <BaseScreen
        title="Privacy Notice"
        subtitle="Last updated September 30, 2022"
        showButton={false}
        showBackButton={false}
      >
        <PrivacyNotice
          onBack={() => router.back()}
          onSubmit={() => {
            setPrescreenState(prescreen => ({...prescreen, privacy: true}))
            router.push("/numbers" + window.location.search)
          }}
          recordEvent={recordEvent}
        />
      </BaseScreen>
    </LayoutScreen>
  )
}

// TODO took these screens out of the spec so any audit/metrics data won't be in the answers any more
// idk if we need to fix that or not
export function KnowNumbersScreen() {
  const dispatch = useDispatch()
  const config = useSelector(state => state.config)
  const [searchParams] = useSearchParams()
  const clinician = searchParams.get("clinician")

  const setModal = useContext(ModalContext)
  const { setAuthModalState } = useOutletContext()

  const getStarted = async () => {
    if (clinician) {
      setPrescreenState(prescreen => ({...prescreen, numbers: true}))
      router.push("/product" + window.location.search)
      return
    }

    const maxReached = config.LIMIT_ATTEMPTS && !hasRemainingAttempts()
    if (maxReached) {
      setModal(() => <AttemptModalContent onClose={() => setModal()} />)
      return
    }

    if (!config.OAUTH_ENABLED) {
      // assume guest login if oauth disabled
      await authClient.guestLogin()
      addAttempt()
      setPrescreenState(prescreen => ({...prescreen, numbers: true}))
      dispatch(assessmentStarted())
      return
    }

    setAuthModalState(s => ({...s, show: true}))
  }

  return (
    <LayoutScreen>
      <BaseScreen
        title="Before you can purchase Zena, you need to know"
        showButton={true}
        showBackButton={true}
        onBack={() => router.back()}
        onSubmit={getStarted}
      >
        <ul style={{marginBottom: "16px"}}>
          <li>Your height</li>
          <li>Your weight</li>
          <li>Your actual blood pressure numbers taken within the last 3 months</li>
        </ul>
        <p>If you don't have your details now you can continue to complete the questions but will need to enter the information later.</p>
        <p>If you do not have your blood pressure numbers now you can go away and come back to the health survey later.</p>
        <p>When you have your blood pressure numbers please restart the survey and enter the information to see if Zena is right for you.</p>
        <BloodPressureInfoButton onClick={() => {
          router.push("/numbers/more-info" + window.location.search)
        }} />
      </BaseScreen>
    </LayoutScreen>
  )
}

export function KnowNumbersMoreInfoScreen() {
  return (
    <LayoutScreen>
      <BaseScreen
        title="You need to enter a current (taken in past 3 months) blood pressure"
        showButton={true}
        buttonText="Back"
        onSubmit={() => router.back()}>
          <BloodPressureInfoContent />
      </BaseScreen>
    </LayoutScreen>
  )
}

export function HasUsedProductScreen() {
  const { hasUsed, setHasUsed, onSubmit } = useOutletContext()

  const options = [
    {title: "Yes", value: "yes"},
    {title: "No", value: "no"},
  ]

  return (
    <LayoutScreen>
      <BaseScreen
        title="Have you used this product before?"
        showButton={true}
        showBackButton={true}
        onBack={() => router.back()}
        onSubmit={() => onSubmit(hasUsed === "yes")}
        disableContinue={!hasUsed}
      >
        <RadioList id="used" options={options} value={hasUsed} onSelect={setHasUsed}/>
      </BaseScreen>
    </LayoutScreen>
  )
}

function ResumeModal() {
//  return (
//            <Modal
//              className="modal"
//              overlayClassName="overlay"
//              isOpen={resumeModal.show}
//              onRequestClose={() => {
//                //dispatch({type: "RESUME_MODAL_CLOSED"})
//              }}
//            >
//              <h3> Resume your last assessment, or start a new one?</h3>
//
//              <div className='btn-group'>
//              <div style={buttonContainer}>
//              <AssessmentButton onClick={() => {
//                dispatch({type: "RESUME_MODAL_CLOSED"})
//                navigate(`/assessment/${auth.history.lastAssessment}`)
//              }}> Resume </AssessmentButton>
//              </div>
//
//              <div style={buttonContainer}>
//              <AssessmentButton onClick={() => {
//                dispatch({type: "RESUME_MODAL_CLOSED"})
//                getStarted()
//              }}> Get Started </AssessmentButton>
//              </div>
//              </div>
//
//            </Modal>
//  )
}
