import { useState, useEffect, useContext } from 'react'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { questions } from '../spec/utils'
import { decodeAssessmentState } from '../components/Assessment'
import Button from '../components/Button'
import icons from '../icn/index'
import '../css/Summary.css'
import ModalContext from '../modal'
import { AssessmentButton } from '../components/AssessmentComponents'
import { Auth } from '../components/Auth'
import { BaseModal } from '../components/MoreInfo'
import LayoutScreen from '../components/Layout'
import useResponsive from '../responsive'
import { googleAuth, appleAuth } from '../components/Auth'
import { useSelector } from 'react-redux'
import { useAudit } from '../audit'
import { useStateWithSessionStorage } from '../hooks'

function index(spec, id) {
  for (let i = 0; i < spec.children.length; i++) {
    const s = spec.children[i]
    const found = questions(s).find(q => q.id === id)
    if (found) {
      return i
    }
  }
  return -1
}

const summaryQuestion = {
  buying: "Buying Zena",
  periods: "Menstrual Periods",
  height: "Height",
  weight: "Weight",
  smoking: "Smoking",
  birthyear: "Year of Birth",
  birthmonth: "Have you had your birthday yet this year?",
  bpMeds: "Blood Pressure Medication",
  initialKnowBpNumbers: "Knows Blood Pressure Numbers",
  initialSystolic: "Blood Pressure",
  initialDiastolic: "Diastolic",
  cardiac_risks: "Chest Pain/Heart Attack/Stroke/Mini Stroke",
  bloodclot: "Blood Clots",
  heartValveProblems: "Other Heart Issues",
  migraines: "Migraines with Aura",
  cancer_radio: "Ever Had Cancer",
  cancer: "Any Cancer",
  liverDisease: "Liver Disease",
  vaginalBleeding: "Unexplained Vaginal Bleeding",
  hormonalBirthControl: "Other Hormonal Birth Controls",
  birthcontrolA: "Hormonal Birth Control",
  birthcontrolB: "Hormonal Birth Control",
  diabetes: "Diabetic",
  pregnancy: "Currently Pregnant",
  breastFeeding: "Breastfeeding",
  pregnancyLoss: "Pregnancy ended in last 4 weeks",
  conditions: "Medical Conditions",
  hepatitisMeds: "Hepatitis C Medications",
  thyroidMeds: "Thyroid Disease Medications",
  epilepsyMeds: "Epilepsy Medications",
  hivMeds: "HIV Medications",
  cholesterolMeds: "Cholesterol Medications",
  antifungalproducts: "Antifungal Products",
  antibacterialMeds: "Antifungal Medications",
  otherMeds: "Other Medications",
  gallbladderDisease: "Gallbladder Disease",
  depression: "Depression",
  diagnosedDepression: "Clinically Diagnosed Depression",
}

const excludeIf = {
  //depression: "diagnosedDepression",
}

const editOverride = {
  //diagnosedDepression: "depression",
}

function displayList(answer, options = []) {
  if (answer.length == 0) {
    return "None of these"
  }

  const text = {}
  for (const opt of options) {
    text[opt.value] = opt.title
  }

  return answer.map(val => text[val] || val).join(", ")
}

const formatting = {
  "no": "No",
  "yes": "Yes",
}

const SKIP = {}

const formatters = {
  buying: ({buying}, {options}) => options.filter(({value}) => value == buying)[0].text,
  height: ({height}) => `${height/12|0} feet, ${height%12} inches`,
  weight: ({weight}) => `${weight} lbs`,
  initialSystolic: ({systolic, diastolic}) => `${systolic} / ${diastolic}`,
  initialDiastolic: () => SKIP
}


function ConfirmModal({onGoBack, onConfirm}) {
  return (
    <div className="summary-modal">
      <p style={{paddingBottom: "78px"}}>By clicking <b>Finish</b>, I confirm that all information I have provided is truthful and accurate to the best of my knowledge.</p>
      <div>
        <div>
          <AssessmentButton variant="primary outline" onClick={onGoBack}>Go Back</AssessmentButton>
        </div>
        <div style={{paddingTop: "16px"}}>
          <AssessmentButton variant="primary" onClick={onConfirm}>Finish</AssessmentButton>
        </div>
      </div>
    </div>
  )
}

function makeFoldQuestions({answers, questions}) {
  return (xs, q) => {
    const {id, type, question, options} = q

    // if (excludeIf[id] && answers[excludeIf[id]]) {
    //   return xs
    // }

    let answer = answers[id]
    const formatter = formatters[id]

    if (formatter) {
      answer = formatter(answers, q)
      if (answer === SKIP) { return xs }

    } else if (Array.isArray(answer)) {
      answer = displayList(answer, options)

    } else if (options) {
      const opt = options.filter(opt => opt.value == answer)
      if (opt.length) {
        answer = opt[0].title
      }
    }

    return [...xs, { id, answer }]
  }
}

export default function SummaryPage({spec, summaryIds, answers, recordType, onEdit, onConfirm}) {
  const onEvent = useAudit()

  const { repurchase } = answers

  const qs = questions(spec)
    .filter(q => q.type !== "virtual")
    .filter(q => !(q.id == "initialKnowBpNumbers" && repurchase))

  const kvs = qs
    .filter(q => summaryIds.includes(q.id))
    .reduce(makeFoldQuestions({questions: qs, answers}), [])

  // const getIcon = (id) => {
  //   const iconMap = {
  //     height: 'ruler',
  //     weight: 'scale',
  //     birthday: 'gift',
  //     smoking: `smoking-regular`
  //   }

  //   return iconMap[id]
  // }

  const setModal = useContext(ModalContext)
  const authed = useSelector(state => state.auth.authed)
  const [signInModal, setSignInModal] = useStateWithSessionStorage("SummaryPage_signInModal", {show: false})

  const handleConfirm = () => {
    onEvent({type: "SUMMARY_CONFIRM_CLICK"})

    setModal(() => (
    <ConfirmModal
      onGoBack={() => {
        onEvent({type: "SUMMARY_CONFIRM_MODAL_GO_BACK"})
        setModal()
      }}
      onConfirm={() => {
        onEvent({type: "SUMMARY_CONFIRM_MODAL_CONFIRM"})
        setModal()
        if (!authed && recordType !== "clinician") {
          setSignInModal({show: true})
        } else {
          onConfirm()
        }
      }} />
     ))
  }

  return (
    <>
    <SignInModal
      isOpen={signInModal.show}
      onApple={async () => {
        await appleAuth("signup", true)
        onConfirm()
      }}
      onGoogle={async () => {
        await googleAuth("signup", true)
        onConfirm()
      }}
      onGuest={onConfirm} />
    <div className="summary">
      <div className="list-container">
        <h1> { repurchase
                ? "Has anything changed since the last time you visited?"
                : "Please check if your answers were recorded correctly."
            }
        </h1>
        <p className="pencil-tip">
          <span>Click </span>
          <img src={icons['edit']} alt="" style={{verticalAlign: "text-bottom"}}/>
          <span className='sr-only'>edit</span>
          <span> if needed to edit.</span>
        </p>
        <div className="list">
          {
            kvs.map(({id, answer}) => (
              <div key={id} className="summary-item">
                <div>
                  <h2>{ summaryQuestion[id] || id }</h2>
                  <p>{ answer }</p>
                </div>

                <button onClick={() => {onEdit(index(spec, editOverride[id] || id))}}>
                  <img src={icons['edit']} alt="" />
                  <span className='sr-only'>{ `edit ${id}` }</span>
                </button>
              </div>
            ))
          }
        </div>
      </div>

      <div className="button-container">
        <Button variant="primary" onClick={() => handleConfirm()}>Confirm</Button>
      </div>
    </div>
    </>
  )
}

function SignInModal({isOpen, onGoogle, onApple, onGuest}) {
  const {desktop} = useResponsive()

  const style = {
    layout: {
      navbar: {
        header: {
          background: desktop ? "rgba(252, 252, 252, 1)" : "white"
        }
      },
      layoutMain: {
        background: "white",
      },
      layoutMainMobileInner: {
        width: undefined
      }
    },
    content: {
      title: {
        fontSize: "27px"
      },
      termsText: {
        fontSize: "16px",
        lineHeight: "20px",
        color: "rgba(99, 99, 99, 1)",
        textDecorationSkipInk: "none",
      },
    }
  }

  const TermsLink = ({children}) => {
    const style = {
      textDecoration: "underline",
      color: "inherit",
      fontWeight: "inherit",
    }
    return (
      <a href="#" onClick={e => e.preventDefault()} style={style}>{children}</a>
    )
  }

  return (
    <>
      <BaseModal isOpen={isOpen}>
        <LayoutScreen style={style.layout} showProfile={false}>
          <div style={{display: "flex", flexDirection: "column", maxWidth: "392px", margin: "0 28px 32px 28px"}}>
            <div style={{margin: desktop ? "0 28px": undefined}}>
              <h1 className="secondary" style={{...style.content.title, padding: 0, textAlign: "center", marginBottom: "40px"}}>Don't lose your progress, create an account.</h1>
              <div style={{margin: "0 0 114px 0"}}>
              <Auth onSelect={(name, _) => {
                if (name === "guest") {
                  onGuest()
                } else if (name === "google") {
                  onGoogle()
                } else if (name === "apple") {
                  onApple()
                }
              }} />
              </div>
            </div>
            <p style={{...style.content.termsText, margin: 0}}>
              By using your Google or Apple ID you accept our <TermsLink>terms of use</TermsLink> and <TermsLink>privacy policy</TermsLink>.
              <br />
              <br />
              <br />
              If you continue as a guest, your answers will not be saved when you quit and you will need to restart.
            </p>
          </div>
        </LayoutScreen>
      </BaseModal>
    </>
  )
}
