import { useSearchParams, useParams, searchParams } from "react-router-dom";
import { client } from "../client"


import "../css/Outcome.css";
import success from "../img/success.png";
import caution from "../img/caution.png";
import arrowBack from "../icn/arrow-back.svg";
import profile from "../icn/profile.svg";
import { useState, useEffect, createElement } from 'react';
import { AssessmentButton, RadioSelectList, createComponent } from '../components/AssessmentComponents';
import { BcCheckbox } from '../components/ConfirmModals';
import ModalContext from '../modal'
import { useContext } from 'react'
import { useAudit } from '../audit'
import { useMetrics } from "../metrics"
import { BloodPressureInfo } from '../components/MoreInfo'
import history from '../history'
import { BaseScreen } from "../components/Assessment"
import { BpNumbersRecentModal } from "../components/ConfirmModals"
import router from '../history'
import { BpModal } from "../components/ConfirmModals"
import LayoutScreen from "../components/Layout"
import images from '../img/index'
import { usePrompt, skipPrompt } from '../prompt'
import { CheckoutPrompt } from './CheckoutTmp'

import RouterLink from "../components/RouterLink";
import useResponsive from "../responsive"
import { useStateWithSessionStorage } from "../hooks";

function join(arr) {
  if (arr.length == 0) {
    return ''
  }

  if (arr.length == 1) {
    return arr[0]
  }
  return arr.slice(0, arr.length - 1).join(', ') + ` and ${arr[arr.length - 1]}`
}

function spaces(arr) {
  return arr.map(e => e.replaceAll('_', ' '))
}

// function genAdbuFlags(answers) {
//   const flags = []
// 
//   if (answers.bmi_coc) {
//     const s = "a BMI of " + answers.bmi.toFixed(1)
//     flags.push(s)
//   }
// 
//   if (answers.cancer.includes('cancer_other')) {
//     flags.push("has other cancer")
//   }
// 
//   if (answers.conditions.includes('highCholesterol')) {
//     flags.push("has high cholesterol")
//   }
// 
//   const conditionsMeds = [
//     ...(answers.hepatitisMeds || []),
//     ...(answers.thyroidMeds || []),
//     ...(answers.epilepsyMeds || []),
//     ...(answers.hivMeds || []),
//     ...(answers.cholesterolMeds || [])
//   ]
// 
//   if (conditionsMeds.length > 0) {
//     const s = 'taking ' + join(spaces(conditionsMeds))
//     flags.push(s)
//   }
// 
//   if (answers.antibacterialMeds && answers.antibacterialMeds.length > 0) {
//     const s = 'currently taking ' + join(spaces(answers.antibacterialMeds))
//     flags.push(s)
//   }
// 
//   if (answers.otherMeds.length > 0) {
//     const s = 'currently taking ' + join(spaces(answers.otherMeds))
//     flags.push(s)
//   }
// 
//   if (answers.gallbladderDisease == 'yes') {
//     flags.push("has gallbladder disease")
//   }
// 
//   if (answers.diagnosedDepression == 'yes') {
//     flags.push("has clinically diagnosed depression")
//   }
// 
//   return flags
// }

// TODO might need to update with new design
function ConfirmModal({onConfirm}) {
  const [checked, setChecked ] = useState(false)
  useAudit({type: "OUTCOME_ATTESTATION_CONFIRM_MODAL_DISPLAYED"})

  return (
    <div className="outcome-confirm">
      <div>
        <p style={{marginBottom: "24px"}}>
        By clicking the checkmark, I confirm that <b>I have talked to a doctor who confirmed</b> that Zena is safe for me based on the information I provided.
        </p>
        <BcCheckbox
          checked={checked}
          onCheck={() => setChecked(v => !v)}
          title="I have confirmed with a doctor that Zena is safe for me."
          variant="primary"
          style={{
            checkbox: {
              alignItems: "flex-start"
            },
            title: {
              fontWeight: "normal"
            },
            }}/>
      </div>
      <div style={{marginTop: "32px"}}>
        <AssessmentButton variant="primary" disabled={!checked} onClick={() => onConfirm()}>Confirm</AssessmentButton>
      </div>
    </div>
  )
}

function getMedications(answers) {
  let medications = [
    ...(answers.hepatitisMeds || []),
    ...(answers.thyroidMeds || []),
    ...(answers.epilepsyMeds || []),
    ...(answers.hivMeds || []),
    ...(answers.cholesterolMeds || []),
    ...(answers.antibacterialMeds || []),
    ...(answers.otherMeds || [])
  ]
  return medications
} 

function formatMedications(medications) {
  return medications.map(m => m.replaceAll("_", " "))
}

export function AdbuList({answers}) {
  const flags = {
    highBMI: answers.bmi >= 35,
    otherCancer: (answers.cancer || []).includes('cancer_other'), // cancer is conditional so default if undefined
    highCholesterol: answers.conditions.includes('highCholesterol'),
    gallbladderDisease: answers.gallbladderDisease == 'yes',
    clinicallyDiagnosedDepression: answers.diagnosedDepression == 'yes'
  }

  const conditions = []
  if (flags.otherCancer) {
    conditions.push("other cancer")
  }

  if (flags.highCholesterol) {
    conditions.push("high cholesterol")
  }

  if (flags.gallbladderDisease) {
    conditions.push("gallbladder disease")
  }

  if (flags.clinicallyDiagnosedDepression) {
    conditions.push("clinically diagnosed depression")
  }

  const medications = formatMedications(getMedications(answers))
  
  return (
    <div>
      <ul>
        {flags.highBMI && (
            <li>you have a BMI greater than 35</li>
          )
        }
        {
          conditions.length > 0 && (
            <li>you have or have had {join(conditions)}</li>
          )
        }
        {
          medications.length > 0 && (
            <li>you are taking {join(medications)}</li>
          )
        }
      </ul>
    </div>
  )
}

function AdbuScreenConfirmationModalContent({onBack, onConfirm}) {
  const [checked, setChecked ] = useState(false)
  const onEvent = useAudit({type: "OUTCOME_ATTESTATION_CONFIRM_MODAL_DISPLAYED"})

  return (
    <div>
       <p style={{margin: "16px 0 24px 0"}}>By clicking the checkmark box, I confirm that I will need to <b>ask a doctor if Zena</b> is safe for me based on my information.</p>
       <BcCheckbox
          checked={checked}
          onCheck={() => setChecked(v => !v)}
          title={"I have read and understand"}
          variant="primary"
          style={{
            checkbox: {alignItems: "flex-start", minHeight: "initial"},
            title: {fontWeight: "normal"}
          }} />
        <button className="btn primary outline thin" onClick={() => onBack()} style={{margin: "24px 0 16px 0"}}>Go Back</button>
        <button className="btn primary" disabled={!checked} onClick={() => {
          onEvent({type: "OUTCOME_ATTESTATION_CONFIRM_MODAL_CONFIRMED"})
          onConfirm()
        }}>Confirm</button>
    </div>
  )
}

export function AdbuScreen({answers, onConfirm, needsBloodPressure}) {
  const setModal = useContext(ModalContext)
  const onEvent = useAudit({type: "OUTCOME_ADBU_DISPLAYED"})

  const showConfirmModal = () => {
    onEvent({type: "OUTCOME_ADBU_I_UNDERSTAND_CLICKED"})
    setModal(() => (
      <AdbuScreenConfirmationModalContent
        onBack={() => {
          onEvent({type: "OUTCOME_ADBU_I_UNDERSTAND_MODAL_GO_BACK"})
          setModal()
        }}
        onConfirm={() => {
          onEvent({type: "OUTCOME_ADBU_I_UNDERSTAND_MODAL_CONFIRM"})
          setModal()
          onConfirm()
        }}/>
    ))
  }

  return (
    <div className="outcome-content">
      <h1 className="secondary">
        {needsBloodPressure && (
          <>
            <span>You need your recent blood pressure numbers.</span><br />
            <br />
          </>
        )}
        <span>Ask a doctor if Zena is safe for you.</span>
      </h1>
      <div className="content">
        <p>Based on the information you provided you will need to talk to a doctor because:</p>
        <AdbuList answers={answers} />
        <p>Your doctor may decide that it is OK for you to use Zena or may prescribe an alternative birth control product.</p>
        <p>If your doctor says it's OK to use Zena please come back and complete the survey.</p>
        <div style={{display: "flex", justifyContent: "center", marginTop: "32px"}}>
          <button className="btn primary" onClick={showConfirmModal}>I understand</button>
        </div>
      </div>
    </div>
  )
}

function Purchase({assessmentId, referenceNumberComponent, recordType}) {
  useAudit({type: "OUTCOME_SAFE_TO_USE_DISPLAYED"})
  const recordMetric = useMetrics()

  const isClinician = recordType === "clinician"
  return (
    <LayoutScreen
      heroSrc={images.success}
      showProfile={true}>
        <div className="outcome-content" style={{maxWidth: "500px"}}>
          <h1 className="secondary">You’re ready to purchase Zena.</h1>
          <div className="content">
            <p>
              Are you buying in-store or online?
            </p>
            <div style={{display: "flex", flexDirection: "column", gap: "24px", marginTop: "60px", alignItems: "center"}}>
              <button disabled={isClinician} className="btn primary" onClick={() => {
                skipPrompt()
                recordMetric({purchaseOption: "instore"})
                history.push(`/qr/${assessmentId}` + window.location.search)
              }}>Buy in-store now</button>
              <button disabled={isClinician} className="btn primary outline" onClick={() => {
                skipPrompt()
                recordMetric({purchaseOption: "online"})
                history.push(`/checkout/${assessmentId}` + window.location.search)
              }}>Order online</button>
            </div>
          </div>
        </div>
      { createElement(referenceNumberComponent, {}, []) }
    </LayoutScreen>
  )
}

function DNU() {
  useAudit({type: "OUTCOME_ATTESTATION_NO_DISPLAYED"})

  return (
    <div className="outcome-content">
      <h1 className="secondary">Zena may not be right for you.</h1>
      <div className="content">
        <p>
          Based on your medical history you will not be eligible to order Zena.
        </p>
      </div>
    </div>
  )
}

function BloodPressureReturning({onClick}) {
  useAudit({type: "OUTCOME_BLOODPRESSURE_DISPLAYED"})

  return (
    <div className="outcome-content">
      <h1 className="secondary">Based on your answers, Zena could be right for you to use!</h1>
      <div className="content">
        <p>You need to enter a current (past 3 months) blood pressure.</p>
        <p>There are easy ways to get your BP numbers measured.</p>
        <p style={{marginBottom: "32px"}}>When you have your blood pressure numbers you can return to the survey to enter them and confirm if Zena is safe for you.</p>
        <BloodPressureInfo style={{width: "100%"}} />
        <div style={{marginTop: 36, display: "flex", justifyContent: "center"}}>
          <div style={{display: "flex", flexDirection: "column"}}>
            <span style={{marginBottom: "16px"}}>Ready to enter your blood pressure?</span>
            <AssessmentButton variant="primary outline thin" onClick={() => onClick()} style={{fontSize: "16px"}}>
              Enter blood pressure numbers
            </AssessmentButton>
          </div>
        </div>
      </div>
    </div>
  )
}

function BloodPressure({onReturnEdit}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const returning = searchParams.get("returning")
  useAudit({type: "OUTCOME_BLOODPRESSURE_DISPLAYED"})

  console.log(searchParams, searchParams.get("returning"), returning)

  if (returning) {
    return (
      <BloodPressureReturning onReturnEdit={onReturnEdit}/>
    )
  }

  return (
    <div className="outcome-content">
      <h1 className="secondary">Based on your answers, Zena could be right for you to use!</h1>
      <div className="content">
        <p>You need to enter a current (past 3 months) blood pressure.</p>
        <p>There are easy ways to get your BP numbers measured.</p>
        <p style={{marginBottom: "32px"}}>When you have your blood pressure numbers you can return to the survey to enter them and confirm if Zena is safe for you.</p>
        <BloodPressureInfo style={{width: "100%"}} />
      </div>
    </div>
  )
}

function ReturningAskedDoctorScreen({value, onBack, onSubmit}) {
  const [_value, _setValue] = useState(value)
  const onEvent = useAudit({type: "OUTCOME_ASKED_DOCTOR_DISPLAYED"})

  useEffect(() => {
    _setValue(value)
  }, [value])

  return (
    <BaseScreen
      title="Did you ask a doctor if Zena is safe for you?"
      onSubmit={() => {
        onSubmit({"askedDoctor": _value})
      }}
      disableContinue={!_value}
      showBackButton={true}
      onBack={onBack}>
        <RadioSelectList
          id="askedDoctor"
          variant="primary"
          value={_value}
          options={[
            {title: "Yes", value: "yes"},
            {title: "No", value: "no"}
          ]}
          onEvent={(e) => {
            onEvent(e)
            if (e.type === "UPDATED_ANSWER") {
              _setValue(e.newValue)
            }
          }} />
    </BaseScreen>
  )
}

function ReturningDoctorAttestationScreen({setModal, value, onBack, onSubmit}) {
  const [_value, _setValue] = useState(value)
  const onEvent = useAudit({type: "OUTCOME_DOCTOR_ATTESTATION_DISPLAYED"})

  useEffect(() => {
    _setValue(value)
  }, [value])

  const showAttestationModal = () => {
    setModal(() => (
      <ConfirmModal
        onConfirm={() => {
          onEvent({type: "UPDATED_ANSWER", componentId: "doctorAttestationConfirm", newValue: "yes"})
          setModal()
          onSubmit({
            "doctorAttestation": _value,
            "doctorAttestationConfirm": "yes",
          })
        }} />
    ))
  }

  return (
    <BaseScreen
      title="Did your doctor say Zena is safe for you?"
      onSubmit={() => {
        _value === "yes"
        ? showAttestationModal()
        : onSubmit({
            "doctorAttestation": _value,
          })
      }}
      disableContinue={!_value}
      showBackButton={true}
      onBack={onBack}>
        <RadioSelectList
          id="doctorAttestation"
          variant="primary"
          value={_value}
          options={[
            {title: "Yes", value: "yes"},
            {title: "No", value: "no"}
          ]}
          onEvent={(e) => {
            onEvent(e)
            if (e.type === "UPDATED_ANSWER") {
              _setValue(e.newValue)
            }
          }} />
    </BaseScreen>
  )
}

function DDICaution({medications}) {
  const style = {
    root: {
      padding: "25px 16px 16px 16px",
      background: "rgba(250, 216, 38, 1)",
      borderRadius: "12px",
      fontWeight: "bold",
      display: "flex",
      gap: "8px",
      alignItems: "start"
    },
    img: {
      width: "58px", height: "auto"
    }
  }

  return (
    <div style={style.root}>
      <img src={images["caution-sign"]} alt="" style={style.img}/>
      Use back up birth control while using: {join(formatMedications(medications))}
    </div>
  )
}

function ReturningDDIScreen({medications, onBack, onSubmit}) {
  useAudit({type: "OUTCOME_DDI_SCREEN_DISPLAYED"})

  return (
    <BaseScreen
      title=""
      onSubmit={() => {
        onSubmit({ddiConfirm: "yes"})
      }}
      showBackButton={true}
      onBack={onBack}>
        <DDICaution medications={medications} />
        <p style={{marginTop: "28px"}}>
          You will need to use back up birth control when taking Zena because the 
          other drugs you are using may cause Zena to be less effective. 
          If this concerns you, you should talk to your doctor. 
        </p>
    </BaseScreen>
  )
}

const bloodPressureMainContentSpec = {
  "type": "Column",
  "children": [
    {
      "type": "Paragraph",
      "text": "Blood pressure is made up of two numbers. The top number (systolic) should always be higher than the bottom number (diastolic)."
    },
    {
      "type": "MoreInfoIconPlusComponent",
      "children": [
        {
          "type": "Paragraph",
          "text": "How to read the blood pressure monitor:"
        },
        {
          "type": "Image",
          "src": "blood-pressure"
        }
      ]
    },
    {
      "type": "Row",
      "justify": "center",
      "children": [
        {
          "type": "Row",
          "style": {
            "width": "min(100%, 300px)",
            "justifyContent": "flex-end"
          },
          "children": [
            {
              "type": "Column",
              "style": {
                "flex": "0 0 auto",
                "gap": "25px",
                "padding": "19px",
                "backgroundColor": "rgba(245, 245, 245, 1)",
                "border": "1px solid #9E9E9E",
                "borderRadius": "5px",
                "width": "153px"
              },
              "children": [
                {
                  "id": "systolic",
                  "type": "NumericInput",
                  "question": "Systolic Pressure<br>(top number)",
                  "validation": [
                    {
                      "cond": "answer.length === 0 || answer[0] === '0' || Number.isNaN(Number(answer)) || Number(answer) <= 0",
                      "err": "Please enter valid number"
                    }
                  ],
                  "variant": "primary",
                  "maxLength": 3,
                  "nextFocus": "diastolic",
                  "style": {
                    "base": {
                      "position": "relative"
                    },
                    "input": {
                      "textAlign": "center"
                    },
                    "label": {
                      "textAlign": "center",
                      "position": "absolute",
                      "top": "6px",
                      "left": "calc(-19px - 150px)"
                    }
                  }
                },
                {
                  "type": "Spacer",
                  "style": {
                    "height": "1px"
                  }
                },
                {
                  "id": "diastolic",
                  "type": "NumericInput",
                  "question": "Diastolic Pressure<br>(bottom number)",
                  "validation": [
                    {
                      "cond": "answer.length === 0 || answer[0] === '0' || Number.isNaN(Number(answer)) || Number(answer) <= 0",
                      "err": "Please enter valid number"
                    }
                  ],
                  "variant": "primary",
                  "maxLength": 3,
                  "style": {
                    "base": {
                      "position": "relative"
                    },
                    "input": {
                      "textAlign": "center"
                    },
                    "label": {
                      "textAlign": "center",
                      "position": "absolute",
                      "top": "6px",
                      "left": "calc(-19px - 150px)"
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

function ReturningBloodPressureScreen({setModal, onBack, onSubmit}) {
  const [systolic, setSystolic] = useState('')
  const [diastolic, setDiastolic] = useState('')
  const [shouldShowErrs, setShouldShowErrs] = useState(false)
  const onEvent = useAudit({type: "OUTCOME_BLOODPRESSURE_INPUT_DISPLAYED"})
 
  const checkIsInvalid = answer => answer.length === 0 || answer[0] === '0' || Number.isNaN(Number(answer)) || Number(answer) <= 0

  const screenErrs = {}
  if (checkIsInvalid(systolic)) {
    screenErrs.systolic = "Please enter valid number"
  }
  if (checkIsInvalid(diastolic)) {
    screenErrs.diastolic = "Please enter valid number"
  }

  const showRecentModal = () => {
    onEvent({type: "OUTCOME_BLOODPRESSURE_RECENT_MODAL_DISPLAYED"})
    setModal(() => (
      <BpNumbersRecentModal
        id="returningBpNumbersRecent"
        onEvent={() => {}}
        onConfirm={({ returningBpNumbersRecent }) => {
          setModal()
          onSubmit({
            returningSystolic: systolic,
            returningDiastolic: diastolic,
            returningKnowBpNumbers: "yes",
            returningBpNumbersRecent
          })
        }} />
    ))
  }

  const showWarningModal = () => {
    onEvent({type: "OUTCOME_BLOODPRESSURE_WARNING_MODAL_DISPLAYED"})
    setModal(() => (
      <BpModal
        systolic={systolic}
        diastolic={diastolic}
        onConfirm={() => {
          setModal()
          showRecentModal()
        }}
        onClose={() => {
          setModal()
        }} />
    ))
  }

  // HACK
  const {mobile} = useResponsive()

  return (
    <BaseScreen
      title="What is your most recent blood pressure?"
      onSubmit={() => {
        if (Object.keys(screenErrs).length > 0) {
          setShouldShowErrs(true)
          return
        }

        if (Number(systolic) <= Number(diastolic)) {
          showWarningModal()
        } else {
          showRecentModal()
        }
      }}
      disableContinue={!systolic || !diastolic}
      showBackButton={true}
      backButtonText="I don't know"
      style={mobile ? {buttonContainer: {flexWrap: "wrap"}} : {}}
      onBack={onBack}>
        {
          createComponent(bloodPressureMainContentSpec, {
            answers: {systolic, diastolic},
            getValue: (qid) => {
              if (qid === "systolic") {
                return systolic
              } else if (qid === "diastolic") {
                return diastolic
              }
            },
            onEvent: (e) => {
              onEvent(e)
              if (e.type === "UPDATED_ANSWER") {
                if (e.componentId === "systolic") {
                  setSystolic(e.newValue)
                } else if (e.componentId === "diastolic") {
                  setDiastolic(e.newValue)
                }
              }
            },
            screenErrs,
            shouldShowErrs,
            hideErrs: () => {
              setShouldShowErrs(false)
            },
          })
        }
    </BaseScreen>
  )
}

// note: doctor attest no dnu is handled in the spec now
// function ReturningDnuScreen({onHome}) {
//   // TODO - placeholder text/tbd? and fix style
//   useAudit({type: "OUTCOME_RETURNING_DNU_DISPLAYED"})

//   return (
//     <BaseScreen
//       title="Thank you for visiting!"
//       showBackButton={false}
//       showButton={false}>
//         <p>Zena is not right for you, because:</p>
//         <ul>
//           <li>{"<< ADBU 1 >>"}</li>
//           <li>{"<< ADBU 2 >>"}</li>
//         </ul>
//         <p>You need a doctor's approval to purchase Zena.</p>
//         <a href="#" onClick={(e) => {
//           e.preventDefault()
//           onHome()
//         }}>
//           Back Home
//         </a>
//     </BaseScreen>
//   )
// }

function AdbuScreenThankYou({answers, needsBloodPressure}) {
  useAudit({type: "OUTCOME_THANK_YOU_DISPLAYED"})

  return (
    <div className="outcome-content">
      <h1 className="secondary">Thank you for visiting!</h1>
      <div className="content">
        {needsBloodPressure && <p>You will need to get your recent blood pressure numbers.</p>}
        <p>Based on the information you provided you will need to talk to a doctor because:</p>
        <AdbuList answers={answers} />
        <p>Your doctor may decide that it is OK for you to use Zena or may prescribe an alternative birth control product.</p>
        <p>If your doctor says it's OK to use Zena please come back and complete the survey.</p>
      </div>
    </div>
  )
}

function OutcomeFlow({ state = { outcome: "" }, onReturnEdit, referenceNumberComponent }) {
  const { answers } = state
  const setModal = useContext(ModalContext)
  const adbu = answers.adbu
  const needsBloodPressure = !answers.systolic || !answers.diastolic || answers.knowBpNumbers !== "yes" || answers.bpNumbersRecent !== "yes"
  const [acked, setAcked] = useStateWithSessionStorage("OutcomeFlow_acked", false)
  const { desktop } = useResponsive()
  const [inputting, setInputting] = useStateWithSessionStorage("OutcomeFlow_inputting", false)

  const body = (() => {

    if (adbu) {
      if (!acked) {
        return (
          <AdbuScreen answers={answers} onConfirm={() => setAcked(true)} needsBloodPressure={needsBloodPressure} />
        )
      } else {
        return (
          <AdbuScreenThankYou answers={answers} needsBloodPressure={needsBloodPressure} />
        )
      }
    }
  
    if (needsBloodPressure && !inputting) {
      return (
        <BloodPressureReturning onClick={() => {
          setInputting(true)
        }} />
      )
    }
  
    if (needsBloodPressure && inputting) {
      return (
        <ReturningBloodPressureScreen
          setModal={setModal}
          onBack={() => {
            setInputting(false)
          }}
          onSubmit={async ({returningSystolic, returningDiastolic, returningKnowBpNumbers, returningBpNumbersRecent}) => {
            await onReturnEdit("finalBloodPressureScreen", {
              finalSystolic: returningSystolic,
              finalDiastolic: returningDiastolic,
              finalKnowBpNumbers: returningKnowBpNumbers,
              finalBpNumbersRecent: returningBpNumbersRecent,
            })
            setInputting(false)
          }} />
      )
    }

    return null

  })()

  return (
    <LayoutScreen
      heroSrc={(acked && !desktop) || inputting ? "" : images.caution}
      showProfile={true}
      white={acked && !desktop}>
      { body }
      { !inputting && createElement(referenceNumberComponent, {}, []) }
    </LayoutScreen>
  )
}

function ReturningFlow({ state = { outcome: "" }, onReturnEdit, assessmentId }) {
  const { answers } = state
  const setModal = useContext(ModalContext)

  const adbu = answers.adbu
  const needsBloodPressure = !answers.systolic || !answers.diastolic || answers.knowBpNumbers !== "yes" || answers.bpNumbersRecent !== "yes"
  const ddi = answers.ddi

  const [currentReturningScreen, setCurrentReturningScreen] = useState((() => {
    if (adbu) {
      return "asked"
    } else if (needsBloodPressure) {
      return "bp/inputs"
    }
  })())

  const body = (() => {

    if (currentReturningScreen === "asked") {
      return (
        <ReturningAskedDoctorScreen
          value={answers.askedDoctor || ''}
          onBack={() => {
            skipPrompt()
            router.push(`/return/${assessmentId}`)
          }}
          onSubmit={async ({askedDoctor}) => {
            await onReturnEdit("returningAskedDoctorScreen", {askedDoctor})
            if (askedDoctor == "no") {
              setCurrentReturningScreen("asked/no")
            } else {
              setCurrentReturningScreen("attestation")
            }
          }} />
      )
    }

    if (currentReturningScreen === "asked/no") {
      return (
        <AdbuScreenThankYou answers={answers} needsBloodPressure={needsBloodPressure} />
      )
    }

    if (currentReturningScreen === "attestation") {
      return (
        <ReturningDoctorAttestationScreen
          setModal={setModal}
          value={answers.doctorAttestation || ''}
          onBack={() => {
            setCurrentReturningScreen("asked")
          }}
          onSubmit={async ({doctorAttestation, doctorAttestationConfirm}) => {
            await onReturnEdit("doctorAttestation", {doctorAttestation, doctorAttestationConfirm})
            if (doctorAttestation === "yes") {
              if (ddi) {
                setCurrentReturningScreen("ddi")
              } else if (needsBloodPressure) {
                setCurrentReturningScreen("bp/inputs")
              }
            }
          }} />
      )
    }

    if (currentReturningScreen === "ddi") {
      return (
        <ReturningDDIScreen
          medications={getMedications(answers)}
          onBack={() => {
            setCurrentReturningScreen("attestation")
          }}
          onSubmit={async ({ddiConfirm}) => {
            await onReturnEdit("ddiScreen", {ddiConfirm})
            if (needsBloodPressure) {
              setCurrentReturningScreen("bp/inputs")
            }
          }} />
      )
    }

    if (currentReturningScreen === "bp/inputs") {
      return (
        <ReturningBloodPressureScreen
          setModal={setModal}
          onBack={() => {
            setCurrentReturningScreen("bp/dontknow")
          }}
          onSubmit={async ({returningSystolic, returningDiastolic, returningKnowBpNumbers, returningBpNumbersRecent}) => {
            if (returningBpNumbersRecent !== "yes") { 
              setCurrentReturningScreen("bp/dontknow")
            } else {
              await onReturnEdit("returningBloodPressureScreen", {returningSystolic, returningDiastolic, returningKnowBpNumbers, returningBpNumbersRecent})
            }
          }} />
      )
    }

    if (currentReturningScreen === "bp/dontknow") {
      return (
        <BloodPressureReturning onClick={() => {
          setCurrentReturningScreen("bp/inputs")
        }} />
      )
    }

    return null

  })()

  return (
    <LayoutScreen
      heroSrc={currentReturningScreen === "bp/dontknow" ? images.caution : ""}
      showProfile={true}
      white={currentReturningScreen === "asked/no"}>
        { body }
    </LayoutScreen>
  )
}

// TODO - hide hero get bp numbers on mobile
export default function OutcomePage(props) {
  const { outcome } = props.state
  
  const [searchParams, setSearchParams] = useSearchParams()
  const returning = searchParams.get("returning")

  if (outcome === "ok") {
    return <Purchase {...props} />
  }

  if (returning) {
    return <ReturningFlow {...props} />
  }

  return <OutcomeFlow {...props} />
}
